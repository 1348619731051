<template>
  <div>
    <span class="label">{{ label }}</span>
    <div class="select-container">
      <div
          @click="toggleSelect"
          class="upper-part cursor-pointer relative w-full left-1 h-9"
      >
        <div class="h-full w-full px-1">
          <span v-if="showPlaceholder" class="placeholder">
            {{ placeholder }}
          </span>
          <Tag
              v-for="(tag, index) in tags"
              :key="index"
              :showTag="tags.length > 0"
              :tag="tag"
              :displayValue="optionDisplayValue"
              class="inline-block mr-1"
              @removeTag="removeTag(index)"
          />
        </div>
        <button
            type="button"
            class="
            absolute
            top-1/2
            focus:outline-none
            transform
            -translate-y-1/2
            right-4
            text-black-lighter text-sm
          "
        >
          <i class="fas fa-chevron-down"></i>
        </button>
      </div>

      <div v-if="showInput" class="w-full px-2 relative my-2">
        <input
            @input="emitKeyword($event)"
            class="input"
            :placeholder="keywordPlaceholder"
            type="text"
            v-model="searchWord"
        />
      </div>
      <div v-if="showResults" class="results overflow-y-auto h-72">
        <template v-for="(option, index) in searchArray">

          <ResultItem
              :key="index"
              :option="option"
              :optionDisplayValue="optionDisplayValue"
              @click.native="pushToTag(option.id)"
          />
        </template>
      </div>
    </div>

    <span
        v-if="showErrMsg"
        class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
    >
      {{ errorMsg }}
    </span>
  </div>
</template>
<script>
import ResultItem from "./ResultItem";
import Tag from "./Tag";

export default {
  name: "MultiSelect",
  components: {
    ResultItem,
    Tag,
  },
  data() {
    return {
      tags: [],
      showInput: false,
      showResults: false,
      searchWord: "",
      searchArray: this.options,
      allOptions: [],
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    searchList: {
      type: Array,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionDisplayValue: {
      type: Function,
      default: (option) => {
        return option.title
      }
    },
    label: {
      type: String,
      default: "Select options",
    },
    placeholder: {
      type: String,
      default: "Select multiple options",
    },
    keywordPlaceholder: {
      type: String,
      default: "Search...",
    },
    showTag: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitKeyword(event) {
      let keyword = event.target.value;
      this.$emit("getKeyword", keyword);
    },
    toggleCheckMark(index) {
      this.options[index].showCheckMark = !this.options[index].showCheckMark;
    },
    removeTag(index) {
      let agencyIndex = this.getIndexInOptionsArray(index);
      this.tags.splice(index, 1);
      this.toggleCheckMark(agencyIndex);

      let array = [];
      this.tags.forEach((element) => {
        array.push(element.id);
      });
      this.$emit("input", array);
    },
    checkIfItemExistInTagArray(index) {
      let id = this.options[index].id;
      let result = this.tags.some((item) => item.id == id);
      return result;
    },
    getIndexInOptionsArray(index) {
      let id = this.tags[index].id;
      let optionIndex = this.options.findIndex((option) => option.id == id);
      return optionIndex;
    },
    getTagsItemIndex(index) {
      let id = this.options[index].id;
      let tagItemIndex = this.tags.findIndex((tag) => tag.id == id);
      return tagItemIndex;
    },
    pushToTag(optionID) {
      let index = this.options.findIndex((x) => {
        return x.id == optionID;
      });
      let itemExistsInTagsArray = this.checkIfItemExistInTagArray(index); //Set boolean to check if item already exists in tags array
      let currentItem = this.options[index]; //Set current item being clicked
      //Push item to tags array if it doesn't exist
      if (itemExistsInTagsArray == false) {
        this.tags.push(currentItem);
      }

      //Remove item from tags array if it exists
      if (itemExistsInTagsArray == true) {
        let i = this.getTagsItemIndex(index);
        this.tags.splice(i, 1);
      }
      //Toggle checkmark of result item
      this.toggleCheckMark(index);
      let array = [];
      this.tags.forEach((element) => {
        array.push(element.id);
      });
      this.$emit("input", array);
    },
    toggleSelect() {
      this.showInput = !this.showInput;
      this.showResults = !this.showResults;
      for (let agency of this.options) {
        agency.showItem = !agency.showItem;
      }
    },
  },
  computed: {
    showPlaceholder() {
      return this.tags < 1;
    },
  },
  watch: {
    searchWord(newVal) {
      if (newVal) {
        this.searchArray = this.options.filter((option) => {
          return this.optionDisplayValue(option).toLowerCase().includes(newVal.toLowerCase());
        });
      } else {
        this.searchArray = this.options;
      }
    },
    options(newVal) {
      this.searchArray = newVal;
      if (this.value.length && newVal.length) {
        this.value.forEach((value) => {
          this.pushToTag(value);
        });
      }
    },
  },

  mounted() {
    if (this.value.length && this.options.length) {
      this.searchArray = this.options;
      this.value.forEach((value) => {
        this.pushToTag(value);
      });
    }
  }
};
</script>

<style scoped>
.select-container {
  @apply relative bg-white border border-grey-dark rounded py-1 h-auto sm:py-2;
}

.input {
  @apply w-full bg-grey-light rounded border border-grey-base;
  @apply text-sm sm:text-base p-2 sm:p-3;
}

.input:focus {
  @apply outline-none border border-teal-base ring-teal-lighter ring-2;
}

.label {
  @apply font-sans font-medium text-black-base mb-1.5 sm:mb-3 inline-block;
  @apply text-sm sm:text-base;
}

.placeholder {
  @apply text-black-lightest font-sans absolute block top-1/2 transform -translate-y-1/2 left-2;
  @apply text-sm sm:text-base;
}
</style>