<template>
  <div v-if="showTag" class="tag">
    <span class="tag-text">{{ displayValue(tag) }}</span>
    <button @click.stop="$emit('removeTag')" class="btn-remove" type="button">
      <i class="fal fa-times"></i>
    </button>
  </div>
</template>
<script>
export default {
  name: "Tag",
  props: {
    tag:{
      type:Object,
      required: true
    },
    displayValue:{
      type:Function,
      default:(tag={title:"Item"} )=>{return tag.title}
    },
    showTag: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.tag {
  @apply rounded-md bg-violet-base text-sm text-white mb-1 sm:mb-0;
}

.tag-text {
  @apply p-2 inline-block text-xs sm:text-sm;
}

.btn-remove {
  @apply border-l border-violet-light py-2 px-3 rounded-r-md h-full focus:outline-none hover:bg-violet-dark duration-300 transition-all ease-in-out;
}
</style>